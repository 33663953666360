#course-goals {
  text-align: center;
}

#course-goals h2 {
  color: #414040;
}

#course-goals ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
