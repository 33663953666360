* {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
}

body {
  margin: 0;
  background-color: #383838;
}

label {
  font-weight: bold;
  margin-bottom: 0.5rem;
  display: block;
  font-size: 1.25rem;
}

input {
  font: inherit;
  padding: 1rem;
  border: 1px solid #ccc;
  font-size: 1.5rem;
  display: block;
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 30px;
  text-align: center;
  color: #242424;
}

input:focus {
  outline: none;
  border-color: #6e0040;
  background-color: #ffdff2;
}

button {
  font: inherit;
  background-color: #6e0040;
  color: white;
  border: 1px solid #6e0040;
  border-radius: 30px;
  padding: 0.5rem 1.5rem;
  cursor: pointer;
  font-size: 2rem;
}

button:hover,
button:active {
  background-color: #b80e71;
  border-color: #b80e71;
}
